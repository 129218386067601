<template>
  <div class="m-view-regulation">
    <Topo :back="true"/>
    <div class="m-breadcrumbs">
      <div class="m-background-breadcrumb">
        <div class="m-breadcrumbs-links">
          <v-container>
            <v-row dense>
              <v-col>
                <v-breadcrumbs :items="breadcrumbs">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <div class="m-body-view">
      <v-container>
        <div class="m-title-view">
          <v-row>
            <v-col>
              <div class="m-body-title">
                <div class="m-title">
                  <span>Campanha <strong>União </strong>para sempre
                  </span>
                </div>
                <div class="m-border"></div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="m-body-regulamento">
          <v-row>
            <v-col>
              <h3>REGULAMENTO DA CAMPANHA "UNIÃO PARA SEMPRE"</h3>
              <h3>UNIÃO FREDERIQUENSE DE FUTEBOL</h3>
              <section class="mt-2">
                <h3>1. OBJETIVO DA CAMPANHA</h3>
                <p>
                  A campanha "UNIÃO PARA SEMPRE" tem como objetivo proporcionar a pessoas físicas e jurídicas a oportunidade de deixar seu nome gravado de forma eterna nas paredes do túnel de acesso ao gramado da ARENA UNIÃO, como reconhecimento de seu apoio ao clube. Este registro será uma marca indelével de contribuição para o sucesso do União Frederiquense de Futebol. O aderente receberá uma réplica da placa com a gravação.
                </p>
              </section>
              <section>
                <h3>2. OS PARTICIPANTES</h3>
                <p>
                  Podem participar da campanha pessoas físicas e jurídicas, sem qualquer restrição quanto à idade ou localização, que tenham interesse em apoiar o clube e participar dessa ação histórica.
                </p>
              </section>
              <section>
                <h3>3. CONDIÇÕES DE PARTICIPAÇÃO</h3>
                <p>
                  Para garantir a gravação do nome no túnel de acesso ao gramado, o participante deverá realizar o pagamento de R$ 2.400,00 (dois mil e quatrocentos reais), conforme opção do interessado.
                </p>
              </section>
              <section>
                <h3>4. FORMA DE PAGAMENTO</h3>
                <ul>
                  <li>
                    <strong>Parcelado:</strong> Em até 24 (vinte e quatro) parcelas monetárias de R$ 100,00 (cem reais), com vencimento todo dia 10 de cada mês, sendo o pagamento via débito em conta nas instituições financeiras Sicredi ou Banrisul, PIX, ou Boleto.
                  </li>
                  <li>
                    <strong>À vista:</strong> Pagamento único de R$ 2.000,00 (dois mil reais), podendo ser feito também via débito em conta, PIX ou boleto.
                  </li>
                </ul>
              </section>
              <section>
                <h3>5. DIREITO AO INGRESSO</h3>
                <p>
                  Além da gravação do nome, a adesão à campanha concederá ao participante o direito de ingressos para o Setor Social da ARENA UNIÃO por duas temporadas. Estes ingressos serão válidos para a fase classificatória de todas as competições em que o União Frederiquense de Futebol participar nas temporadas cobertas pela adesão.
                </p>
              </section>
              <section>
                <h3>6. IDENTIFICAÇÃO NO TÚNEL DE ACESSO</h3>
                <p>
                  O nome gravado no túnel será de uma única pessoa física ou jurídica por adesão. Não serão permitidas gravações com nomes múltiplos ou com conteúdo ofensivo ou que contrariem os princípios e valores do União Frederiquense de Futebol. O clube reserva o direito de vetar qualquer nome ou mensagem que não esteja em conformidade com sua imagem institucional.
                </p>
              </section>
              <section>
                <h3>7. VIGÊNCIA DA CAMPANHA</h3>
                <p>
                  A campanha estará aberta até o preenchimento total do espaço disponível para gravação. O clube reserva o direito de alterar a quantidade de adesões e/ou prorrogar o prazo da campanha caso necessário.
                </p>
              </section>
              <section>
                <h3>8. DISPOSIÇÕES GERAIS</h3>
                <ul>
                  <li>A participação na campanha implica a acessibilidade de todas as condições previstas neste regulamento.</li>
                  <li>Em caso de inadimplência com o pagamento, o participante perderá o direito à gravação do nome, e este será removido da lista de adesões.</li>
                  <li>O clube não se responsabiliza por qualquer tipo de erro ou falha nos dados fornecidos pelos participantes.</li>
                  <li>A adesão à campanha não confere qualquer outro benefício ou direito além dos aqui descritos.</li>
                </ul>
              </section>
              <section>
                <h3>9. CANCELAMENTO E REEMBOLSO</h3>
                <p>
                  Não será permitido o cancelamento da adesão à campanha após a efetivação do pagamento. Caso haja resistência e ou desistência antes da efetivação do pagamento integral, o participante não terá direito ao reembolso e perderá à gravação do nome, e este será removido da lista de adesões.
                </p>
              </section>
              <section>
                <h3>10. CONSIDERAÇÕES FINAIS</h3>
                <p>
                  Este regulamento poderá ser alterado a preferências do União Frederiquense de Futebol, sendo as alterações publicadas no site oficial do clube e/ou comunicadas aos participantes. O participante, ao aderir à campanha, concorda com todos os termos deste regulamento.
                </p>
              </section>
              <p><strong>União Frederiquense de Futebol</strong></p>
              <a href="https://dashboard.uniaofw.com.br/json/arquivos/arquivos/REGULAMENTO DA CAMPANHA UNIAO PARA SEMPRE.docx" download>  <v-icon>mdi-download</v-icon>Baixar regulamento</a>

              <v-card class="m-card-clique" flat>
                <v-card-text>
                  <h3>CAMPANHA UNIÃO PARA SEMPRE</h3>
                  <p>Cadastre-se e apoie o time!</p>
                <v-btn depressed class="m-btn" href="https://docs.google.com/forms/d/e/1FAIpQLSeTArNNmsQs2zjE63Nbiov-CWXlHZcjnUS2zvYE6tCIAlhflw/viewform" target="_blank">
                  Clique aqui
                </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Topo from "../components/Topo";
import Footer from "../components/Footer";
import {parseData} from "../utils";

export default {
  name: "Campanha",
  components: {Topo, Footer},

  data: () => ({
    title: 'Campanha União para sempre',
    breadcrumbs: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
      {
        text: 'Campanha União para sempre',
        disabled: true
      },
    ],
  }),
  methods: {},
  metaInfo() {
    return {
      title: `Campanha União para sempre | ${parseData(this.$store.state.site) ? parseData(this.$store.state.site).name : ''}`,
    }
  }
}
</script>

<style lang="scss">
.m-view-regulation {

  .m-breadcrumbs {
    .m-background-breadcrumb {
      background-image: url(../assets/contato.png);
      background-position: 50%;
      background-size: cover;
    }

    .m-breadcrumbs-links {
      padding-top: 200px;

      .v-breadcrumbs {
        a {
          color: var(--white);
        }

        .v-breadcrumbs__item--disabled {
          color: var(--white);
          opacity: 0.6;
        }

        .v-icon {
          color: var(--white);
        }
      }
    }
  }

  .m-body-view {
    padding: 50px 0 0 0;

    .m-title-view {
      margin-bottom: 30px;

      .m-body-title {
        .m-title {
          text-decoration: none;
          text-transform: uppercase;
          font-size: 24px;
          color: var(--cor-principal);
        }

        .m-border {
          margin-top: 5px;
          width: 135px;
          height: 5px;
          background-color: var(--cor-principal);
        }
      }
    }

    color: var(--grey);

    .m-card-clique{
      margin-top: 30px;
      margin-bottom: 30px;
      border-radius: 10px;
      background-color: var(--faint-gray);

      .m-btn {
        text-align: center;
        color: var(--white);
        height: 50px;
        border-radius: 10px;
        font-weight: 600;
        background-color: var(--cor-principal);
      }
    }
  }
}
</style>
